import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

export type TMobileMenuItem = {
  title: string;
  href?: string;
  icon?: React.ReactElement;
  menuItems?: TMobileMenuItem[];
  onClick?: () => void;
  disabled?: boolean;
};

type TMenuProps = {
  onClickClose?: () => void;
  menuItems?: TMobileMenuItem[];
};

export const MobileMenuList: React.FC<TMenuProps> = ({
  menuItems,
  onClickClose,
}) => {
  return (
    <ul className='flex flex-col gap-2 overflow-y-auto'>
      {(menuItems || []).map(menuItem => {
        return (
          <li
            key={menuItem.title}
            className='flex flex-col gap-2 last:pb-[4.75rem]'
          >
            {menuItem.href && (
              <Link
                href={menuItem.href}
                onClick={e => {
                  if (menuItem.onClick) {
                    e.preventDefault();
                    menuItem.onClick();
                  }
                  onClickClose?.();
                }}
                className={clsx(
                  'flex flex-row flex-nowrap items-center justify-between gap-2 py-4 text-base font-normal leading-extra-loose text-control-950',
                  menuItem.icon ? 'pl-8 pr-4' : 'px-8',
                  {
                    'pointer-events-none cursor-not-allowed opacity-40':
                      menuItem.disabled,
                  },
                )}
              >
                <span>{menuItem.title}</span>
                {!!menuItem.icon && menuItem.icon}
              </Link>
            )}
            {!menuItem.href && (
              <button
                onClick={() => {
                  if (menuItem.onClick) {
                    menuItem.onClick();
                  }
                }}
                className='flex flex-row flex-nowrap items-center justify-between gap-2 py-4 pe-5 ps-8 text-left text-base font-normal leading-extra-loose text-control-950'
              >
                <span>{menuItem.title}</span>
                {!!menuItem.icon && menuItem.icon}
              </button>
            )}
            <div className='h-px bg-surface-200' />
          </li>
        );
      })}
    </ul>
  );
};
