import React from 'react';

import { TMegaMenuHeaderItem } from './types';
import { MenuCard } from '../../atoms/DropdownOverlay';
import { getSpan } from '../../atoms/DropdownOverlay/MenuCard/MenuCard';

const SubMenuV3: React.FC<{
  menuItem: TMegaMenuHeaderItem;
}> = props => {
  const { menuItem } = props;

  if (!menuItem.dropDown) {
    return null;
  }

  let totalTiles = 0;

  return (
    <div className='grid grid-cols-4 gap-x-4 gap-y-12 2xl:mx-auto 2xl:box-content 2xl:w-full 2xl:max-w-[1232px] 2xl:px-4'>
      {menuItem.dropDown.map((dropDownGroup, i) => {
        const isLast = i === (menuItem.dropDown?.length || 0) - 1;

        // Calculate total tiles
        if (dropDownGroup.rowRender === 'tiles') {
          totalTiles += dropDownGroup.rowBody?.length || 0;
        } else {
          totalTiles += getSpan(dropDownGroup.rowRender) || 0;
        }

        // Add empty tiles to fill the row
        if (totalTiles % 4 !== 0 && isLast) {
          const emptyTiles = 4 - (totalTiles % 4);

          return (
            <React.Fragment key={i}>
              {/* Last group */}
              <MenuCard {...dropDownGroup} hasTopline longTopline />
              {/* Empty tiles for long dividers */}
              <MenuCard hasTopline rowRender={`${emptyTiles}col` as '1col'} />
            </React.Fragment>
          );
        }

        return <MenuCard {...dropDownGroup} key={i} hasTopline />;
      })}
    </div>
  );
};

export default SubMenuV3;
