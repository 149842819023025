import React from 'react';

import { TMenuItem } from './SubMenuV2.types';
import { EActionType } from '../../../types';
import { Button, EButtonSize, EButtonTheme, EButtonVariant } from '../Button';

export type TLevel2Props = TMenuItem;

const Level2: React.FC<TLevel2Props> = props => {
  const { menuTitle, subTitle, links } = props;

  return (
    <div className='scrollbar-hidden flex flex-col gap-4 overflow-y-auto py-6 pb-24 md:gap-6 2xl:min-w-[888px] 2xl:pb-6'>
      {menuTitle && (
        <h3 className='max-w-[49rem] text-4xl font-semibold leading-relaxed text-control-950 '>
          {menuTitle}
        </h3>
      )}
      <div className='flex flex-col gap-4'>
        {subTitle && (
          <h4 className='text-base font-medium leading-relaxed text-control-600'>
            {subTitle}
          </h4>
        )}
        {!!links.length && (
          <div className='grid w-full grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-[240px_240px] 2xl:grid-cols-[263px_296px_296px]'>
            {links.map((linkGroup, index) => (
              <ul
                key={`${menuTitle}-${index}`}
                className='flex min-h-10 w-full flex-col gap-2'
              >
                {linkGroup.columns.map((action, key) =>
                  action.type !== EActionType.LinksGroupType ? (
                    <Button
                      key={key}
                      {...action.props}
                      variant={EButtonVariant.Text}
                      theme={EButtonTheme.Tertiary}
                      size={EButtonSize.Medium}
                      className='min-h-10 justify-start pe-4 text-control-950'
                    />
                  ) : null,
                )}
              </ul>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Level2;
