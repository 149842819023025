import { ImageProps } from 'next/image';
import React, { useState } from 'react';

import {
  DropdownMobileMenu,
  TDropdownMobileMenuProps,
} from './DropdownMobileMenu';
import { LocalesMobileMenu } from './LocalesMobileMenu';
import { ESubMenuVersions, MobileMenu, TMobileMenuItem } from './MobileMenu';
import { SubMobileMenu } from './SubMobileMenu';
import { TAction } from '../../../types';

import type {
  THeaderMegaMenuProps,
  TMegaMenuHeaderItem,
} from '../HeaderMegaMenu';

type TDropdownItem = Required<TMegaMenuHeaderItem>['dropDown'][number];

type TLocale = {
  value: string;
  label: string;
  country: string;
};

type TB2brokerMobileMenuProps = {
  menuItems: THeaderMegaMenuProps['menuItems'];
  logoText?: string;
  locales?: TLocale[];
  currentLocale?: string;
  localesHeader?: string;
  logo?: ImageProps | null;
  onClickClose: () => void;
  onClickLocale: (index: number) => void;
  mobileActionButton?: TAction;
  homeLink: string;
};

export const B2brokerMobileMenu: React.FC<TB2brokerMobileMenuProps> = ({
  menuItems,
  logoText,
  locales,
  currentLocale,
  localesHeader,
  logo,
  onClickClose,
  onClickLocale,
  mobileActionButton,
  homeLink,
}) => {
  const [isLocalesOpened, setLocalesOpened] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<TMegaMenuHeaderItem | null>(null);
  const [selectedDropdownItem, setSelectedDropdownItem] =
    useState<TDropdownItem | null>(null);

  const onToggleLocales = (): void => {
    setLocalesOpened(value => !value);
  };

  const onToggleMenuItem = (item?: TMegaMenuHeaderItem): void => {
    setSelectedMenuItem(item || null);
  };

  const onToggleDropdownItem = (item?: TDropdownItem): void => {
    setSelectedDropdownItem(item || null);
  };

  // THIRD-LEVEL SUB-MENU
  if (selectedDropdownItem) {
    return (
      <DropdownMobileMenu
        selectedDropdownItem={
          selectedDropdownItem as TDropdownMobileMenuProps['selectedDropdownItem']
        }
        onClickBack={() => onToggleDropdownItem()}
        onClickClose={onClickClose}
        type={selectedMenuItem?.type ?? ESubMenuVersions.SubMenuV3}
        mobileActionButton={mobileActionButton}
      />
    );
  }

  // SECOND-LEVEL SUB-MENU
  if (selectedMenuItem) {
    return (
      <SubMobileMenu
        selectedMenuItem={selectedMenuItem}
        onClickBack={() => onToggleMenuItem()}
        onClickClose={onClickClose}
        onClickMenuItem={onToggleDropdownItem}
        mobileActionButton={mobileActionButton}
      />
    );
  }

  // LOCALES SUB-MENU
  if (isLocalesOpened && locales?.length && locales.length > 1) {
    return (
      <LocalesMobileMenu
        title={localesHeader}
        locales={locales}
        currentLocale={currentLocale}
        onClickBack={onToggleLocales}
        onClickClose={onClickClose}
        onClickLocale={onClickLocale}
        mobileActionButton={mobileActionButton}
      />
    );
  }

  // FIRST-LEVEL MENU
  const mobileMenuItems = menuItems.map<TMobileMenuItem>(item => ({
    title: item.title,
    href: !item.dropDown ? String(item.titleLink?.[0]?.href || '') : undefined,
    onClick: item.dropDown
      ? () => {
          onToggleMenuItem(item as TMegaMenuHeaderItem);
        }
      : undefined,
  }));

  return (
    <MobileMenu
      title={logoText}
      menuItems={mobileMenuItems}
      logo={logo}
      onClickClose={onClickClose}
      onClickLocales={
        locales?.length && locales.length > 1 ? onToggleLocales : undefined
      }
      type={ESubMenuVersions.SubMenuV3}
      mobileActionButton={mobileActionButton}
      homeLink={homeLink}
    />
  );
};
