import { useRef } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : null;

export const useScrollBlock = (): {
  block: () => void;
  allow: () => void;
} => {
  const scrollBlocked = useRef<boolean>(false);
  const html = safeDocument?.documentElement;
  const body = safeDocument?.body;

  const block = (): void => {
    if (!html || !body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - (html?.clientWidth || 0);
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue('padding-right'),
      ) || 0;

    html.style.position = 'relative';
    html.style.overflow = 'hidden';
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allow = (): void => {
    if (!html || !body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  };

  return { block, allow };
};
