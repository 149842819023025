import { ESubMenuVersions } from '../B2brokerMobileMenu/MobileMenu';

import type { TAction } from '../../../types';
import type { TSubMenuV1Item } from '../../atoms/SubMenuV1';
import type { TSubMenuV2Item } from '../../atoms/SubMenuV2';
import type { Locale, Media } from '@shared/master-types';
import type { ImageProps } from 'next/image';
import type { LinkProps } from 'next/link';

export type TMegaMenuHeaderItemBase = {
  title: string;
  dataGaId?: string;
  id?: string;
  titleLink?: LinkProps[];
};

export type TMegaMenuHeaderItem = TMegaMenuHeaderItemBase & {
  type: ESubMenuVersions.SubMenuV3;
  dropDown?: {
    isLoading?: boolean;
    rowTitle: string;
    rowIcon?: ImageProps | null;
    rowId?: string;
    rowRender?: 'tiles' | '1col' | '2col' | '3col' | '4col';
    rowBody?: {
      description?: string;
      id?: string;
      icon?: ImageProps | null;
      showIconOnMobile?: boolean;
      iconHasLightVariant?: boolean;
      link:
        | (LinkProps & {
            text: string;
            dataGaId?: string;
            'aria-disabled'?: boolean;
          })[]
        | null;
    }[];
  }[];
};

export type TMegaMenuHeaderItemWithoutDropdown = TMegaMenuHeaderItemBase & {
  type: undefined;
  dropDown?: undefined;
};

export type TMegaMenuHeaderItemSubMenuV1 = TMegaMenuHeaderItemBase & {
  type: ESubMenuVersions.SubMenuV1;
  dropDown?: TSubMenuV1Item[];
};

export type TMegaMenuHeaderItemSubMenuV2 = TMegaMenuHeaderItemBase & {
  type: ESubMenuVersions.SubMenuV2;
  dropDown?: TSubMenuV2Item[];
};

export enum ERightMenuActionsPosition {
  Left = 'left',
  Right = 'right',
}

export type THeaderMegaMenuProps = {
  logoIcon: string | Media;
  logoText?: string;
  navMenuLogoTitle?: string;
  languageText?: string;
  customHomeLink?: string;
  menuItems:
    | TMegaMenuHeaderItem[]
    | TMegaMenuHeaderItemSubMenuV1[]
    | TMegaMenuHeaderItemSubMenuV2[]
    | TMegaMenuHeaderItemWithoutDropdown[];
  actions?: TAction[];
  locales?: Locale[];
  withNavMenu?: boolean;
  rightMenuActionsPosition?: ERightMenuActionsPosition;
  mobileActionButton?: TAction;
};

export { ESubMenuVersions } from '../B2brokerMobileMenu/MobileMenu';
