import React from 'react';

import Level1 from './Level1';
import { TMenuItem } from './SubMenuV1.types';

export type TSubMenuV1Props = {
  items: TMenuItem[];
};

const SubMenuV1: React.FC<TSubMenuV1Props> = ({ items }) => {
  return (
    <div className='flex min-h-full overflow-y-auto bg-interface-50 px-0 pb-24 2xl:max-h-[47.25rem] 2xl:max-w-[1440px] 2xl:justify-center 2xl:py-8 2xl:pb-8 2xl:pr-0'>
      <Level1 items={items} />
    </div>
  );
};

export default SubMenuV1;
