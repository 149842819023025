import React, { useState } from 'react';

import Level1 from './Level1';
import Level2 from './Level2';
import { TMenuItem } from './SubMenuV2.types';

export type TSubMenuV2Props = {
  items: TMenuItem[];
};

const SubMenuV2: React.FC<TSubMenuV2Props> = ({ items }) => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);

  const onMenuSelected = (index: number): void => {
    setActiveMenuIndex(index);
  };

  return (
    <div className='flex h-full flex-row gap-8 overflow-y-auto bg-interface-50 px-0 pr-4 xl:gap-12 2xl:max-h-[42.25rem] 2xl:max-w-[1440px] 2xl:justify-center 2xl:pr-0'>
      <Level1
        items={items}
        onMenuSelected={onMenuSelected}
        activeMenuIndex={activeMenuIndex}
      />
      <Level2 {...items[activeMenuIndex]} />
    </div>
  );
};

export default SubMenuV2;
