import { Media } from '@shared/master-types';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import { ReactComponent as IconBack } from '../../../../public/icons/components/horizontalLogo/back.svg';
import toImageProps from '../../../utils/toImageProps';

export type THorizontalLogoProps = {
  logoIcon: string | Media | undefined;
  logoText?: string;
  className?: string;
  hasBackLogo?: boolean;
};

const HorizontalLogo: React.FC<THorizontalLogoProps> = props => {
  const { logoIcon, logoText, className, hasBackLogo = false } = props;

  const iconWithProps = toImageProps(logoIcon);

  return (
    <div
      className={clsx(
        'flex flex-row items-center',
        className,
        !hasBackLogo
          ? 'gap-2 hover:opacity-80'
          : 'gap-1 text-control-600 hover:text-control-650',
      )}
    >
      {iconWithProps && (
        <Image
          {...iconWithProps}
          width={24}
          height={24}
          className={clsx('h-6 w-auto', {
            '2xl:hidden': hasBackLogo,
          })}
          priority
        />
      )}

      <IconBack
        className={clsx('text-header-back-button hidden', {
          '2xl:block rtl:scale-x-[-1]': hasBackLogo,
        })}
      />

      {logoText && (
        <span
          className={clsx(
            !hasBackLogo
              ? 'text-header-logo-text font-semibold'
              : 'text-header-back-button font-medium',
          )}
        >
          {logoText}
        </span>
      )}
    </div>
  );
};

export default HorizontalLogo;
