import React from 'react';

import { TMenuItem } from './SubMenuV1.types';
import { EActionType } from '../../../types';
import { Button, EButtonSize, EButtonTheme, EButtonVariant } from '../Button';

export type TLinkElementProps = TMenuItem;

const LinkElement: React.FC<TLinkElementProps> = props => {
  const { description, action } = props;

  return (
    <div className='flex w-full flex-col gap-2 2xl:w-[296px] 2xl:pr-4'>
      {action.type !== EActionType.LinksGroupType ? (
        <Button
          {...action.props}
          variant={EButtonVariant.Text}
          theme={EButtonTheme.Tertiary}
          size={EButtonSize.Medium}
          className='h-full justify-start rounded-none text-start text-control-950 hover:text-brand-500'
        />
      ) : null}
      {description && (
        <div className='text-base leading-relaxed text-control-600'>
          {description}
        </div>
      )}
    </div>
  );
};

export default LinkElement;
