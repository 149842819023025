import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';

type TToplineProps = {
  showTitle: boolean;
  rowTitle?: string;
  rowIcon?: ImageProps | null;
  isLong?: boolean;
};

export const Topline: React.FC<TToplineProps> = ({
  showTitle,
  rowTitle,
  rowIcon,
  isLong,
}) => {
  return (
    <>
      <div className='flex h-6 w-full flex-row items-center gap-2'>
        {showTitle && (
          <>
            {rowIcon && (
              <Image
                {...rowIcon}
                width={24}
                height={24}
                className='h-6 w-auto'
              />
            )}
            <h3 className='text-header-submenu-title text-sm font-medium uppercase leading-[17.5px] tracking-[0.25rem]'>
              {rowTitle}
            </h3>
          </>
        )}
      </div>
      <div
        className={clsx(
          'border-header-submenu-divider mb-4 mt-2.5 border-b',
          isLong ? 'w-[calc(100%+1rem)]' : 'w-full',
        )}
      />
    </>
  );
};
