import { EActionType } from '@front/shared/ds';
import {
  ESubMenuVersions,
  TMegaMenuHeaderItemSubMenuV2,
} from '@front/shared/ds/HeaderMegaMenu';
import { HeaderDropdown } from '@shared/master-types';

import { usePrepareActions } from '../../../../features/actions/useActions.hook';

type TSubMenu = NonNullable<
  TMegaMenuHeaderItemSubMenuV2['dropDown']
>[number] & {
  title?: string;
  rowTitle?: string;
  rowBody?: {
    link?: [
      {
        text?: string;
        href?: string;
      },
    ];
  }[];
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
};

type TUsePrepareSubMenuV2 = ({
  dropdown,
}: {
  dropdown: HeaderDropdown;
}) => Array<TSubMenu> | undefined;

export const usePrepareSubMenuV2 = (): TUsePrepareSubMenuV2 => {
  const prepareActions = usePrepareActions();

  return ({ dropdown }) =>
    dropdown.dropDownBlock?.[0].blockType === ESubMenuVersions.SubMenuV2
      ? dropdown.dropDownBlock?.[0]?.menuItems?.map(row => {
          if (row.menuType === 'action' && row.action) {
            const [action] = prepareActions([row.action]);
            const text =
              action.type !== EActionType.LinksGroupType ? action.text : '';
            const subMenu: TSubMenu = {
              links: [],
              buttonText: text,
              subTitle: '',
              menuTitle: '',
              action,
            };

            // For mobile menu support
            subMenu.title = text;
            subMenu.rowTitle = text;
            subMenu.onClick = undefined;
            subMenu.href =
              action.type === EActionType.LinkType
                ? String(action.props.href)
                : '';
            subMenu.disabled =
              action.type === EActionType.LinkType
                ? action.props.disabled
                : false;
            return subMenu;
          }

          const subMenu: TSubMenu = {
            links:
              row.dropdowns?.columns?.map(column => ({
                columns: prepareActions(column.column),
              })) ?? [],
            buttonText: row.text ?? '',
            subTitle: row.dropdowns?.subtitle,
            menuTitle: row.dropdowns?.title,
          };

          // For mobile menu support
          subMenu.title = row.text;
          subMenu.rowTitle = row.text;
          subMenu.rowBody =
            subMenu.links
              ?.flatMap(obj => obj.columns)
              .map(link => ({
                link: [
                  link.type === EActionType.LinkType
                    ? {
                        text: link.text,
                        href: link.props.href as string | undefined,
                      }
                    : { text: '', href: '' },
                ],
              })) ?? [];
          return subMenu;
        })
      : [];
};
