import React from 'react';

import LinkElement from './LinkElement';
import { TMenuItem } from './SubMenuV2.types';

export type TLevel1Props = {
  items: TMenuItem[];
  onMenuSelected: (index: number) => void;
  activeMenuIndex: number;
};

const Level1: React.FC<TLevel1Props> = ({
  items,
  onMenuSelected,
  activeMenuIndex,
}) => {
  return (
    <ul className='flex min-w-48 flex-col gap-2 overflow-y-auto overflow-x-hidden border-e border-control-200 py-6 2xl:w-[18.5rem]'>
      {items.map((item, index) => (
        <li key={`${item.buttonText}-${index}`}>
          <LinkElement
            buttonText={item.buttonText}
            isActive={index === activeMenuIndex}
            onClick={() => onMenuSelected(index)}
            onMouseEnter={() => onMenuSelected(index)}
            action={item?.action}
          />
        </li>
      ))}
    </ul>
  );
};

export default Level1;
