import {
  EActionType,
  isRichTextContentEmpty,
  RichText,
} from '@front/shared/ds';
import {
  ESubMenuVersions,
  TMegaMenuHeaderItemSubMenuV1,
} from '@front/shared/ds/HeaderMegaMenu';
import { HeaderDropdown } from '@shared/master-types';

import { usePrepareActions } from '../../../../features/actions/useActions.hook';

type TSubMenu = NonNullable<
  TMegaMenuHeaderItemSubMenuV1['dropDown']
>[number] & {
  rowTitle?: string;
};

type TUsePrepareSubMenuV1 = ({
  dropdown,
}: {
  dropdown: HeaderDropdown;
}) => Array<TSubMenu> | undefined;

export const usePrepareSubMenuV1 = (): TUsePrepareSubMenuV1 => {
  const prepareActions = usePrepareActions();

  return ({ dropdown }) =>
    dropdown.dropDownBlock?.[0].blockType === ESubMenuVersions.SubMenuV1
      ? dropdown.dropDownBlock?.[0]?.menuItems?.map(row => {
          const [action] = prepareActions([row.action]);
          const title =
            action.type !== EActionType.LinksGroupType ? action.text : '';
          return {
            onClick: undefined,
            action,
            description:
              row.description && !isRichTextContentEmpty(row.description) ? (
                <RichText content={row.description} />
              ) : null,
            title,
            href: action.type === EActionType.LinkType ? action.props.href : '',
            rowTitle: title,
          };
        })
      : [];
};
