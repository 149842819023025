import clsx from 'clsx';
import React from 'react';

import { EActionType, TAction } from '../../../types';
import { Button, EButtonSize, EButtonTheme, EButtonVariant } from '../Button';

export type TLinkElementProps = {
  buttonText?: string;
  isActive?: boolean;
  action?: TAction;
  onClick?: () => void;
  onMouseEnter?: () => void;
};

const LinkElement: React.FC<TLinkElementProps> = props => {
  const {
    buttonText,
    isActive: isLinkActive = false,
    action,
    onClick,
    onMouseEnter,
  } = props;
  const actionProps =
    action && action.type !== EActionType.LinksGroupType ? action?.props : {};
  const isActive = !actionProps.disabled && isLinkActive;

  return (
    <div onMouseEnter={onMouseEnter}>
      <Button
        variant={EButtonVariant.Text}
        theme={EButtonTheme.Tertiary}
        size={EButtonSize.Medium}
        className='relative h-10 w-full justify-start rounded-none py-0.5  text-start outline-none'
        onClick={onClick}
        {...actionProps}
      >
        <span
          className={clsx('pr-2 text-base', {
            'text-brand-500': isActive,
            'text-control-950': !isActive,
          })}
        >
          {buttonText}
        </span>
        {isActive && (
          <div className='absolute -end-[1px] top-0 h-full w-[2px] bg-brand-500' />
        )}
      </Button>
    </div>
  );
};

export default LinkElement;
