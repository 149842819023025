import React from 'react';

import LinkElement from './LinkElement';
import { TMenuItem } from './SubMenuV1.types';

export type TLevel1Props = {
  items: TMenuItem[];
};

const Level1: React.FC<TLevel1Props> = ({ items }) => {
  return (
    <ul className='grid grid-cols-1 gap-8 lg:gap-6 xl:gap-8 2xl:grid-cols-4 2xl:gap-x-4 2xl:gap-y-8'>
      {items.map((item, index) => (
        <li key={index}>
          <LinkElement description={item.description} action={item.action} />
        </li>
      ))}
    </ul>
  );
};

export default Level1;
