import { DEFAULT_LOCALE } from '@front/shared';
import { toLinkProps } from '@front/shared/ds';
import {
  ERightMenuActionsPosition,
  ESubMenuVersions,
  HeaderMegaMenu,
  TMegaMenuHeaderItem,
  TMegaMenuHeaderItemSubMenuV1,
  TMegaMenuHeaderItemSubMenuV2,
} from '@front/shared/ds/HeaderMegaMenu';
import { addJurisdictionSupport } from '@front/shared/utils';
import {
  Header as THeader,
  NavMenu as INavMenu,
  Locale,
} from '@shared/master-types';
import React from 'react';

import { useGetHeaderDropdownsQuery } from './hooks/useDropdownQuery';
import { usePrepareSubMenuV1 } from './hooks/usePrepareSubMenuV1';
import { usePrepareSubMenuV2 } from './hooks/usePrepareSubMenuV2';
import { usePrepareSubMenuV3 } from './hooks/usePrepareSubMenuV3';
import { useActions } from '../../../features/actions/useActions.hook';
import { useLocale } from '../../../hooks';

export type THeaderConnectedConnectedProps = Extract<
  Required<THeader>['header'][number],
  { blockType: 'header-mega-menu' }
> & {
  locales?: Locale[];
  withNavMenu?: boolean;
  navMenuLogo?: INavMenu['logoIcon'];
  navMenuLogoTitle?: INavMenu['title'];
};
const HeaderMegaMenuConnected: React.FC<
  THeaderConnectedConnectedProps
> = props => {
  const {
    logoIcon,
    logoText,
    locales = [],
    menuItems,
    menuActions,
    withNavMenu,
    navMenuLogo,
    navMenuLogoTitle,
    languageText,
    customHomeLink,
    rightMenuActionsPosition,
    ctaSettings,
  } = props;

  const { locale: currentLocale } = useLocale();
  const prepareSubMenuV1 = usePrepareSubMenuV1();
  const prepareSubMenuV2 = usePrepareSubMenuV2();
  const prepareSubMenuV3 = usePrepareSubMenuV3();

  const dropdownIds = menuItems
    .map(item => {
      if (item.dropDown && typeof item.dropDown === 'string') {
        return item.dropDown;
      }

      if (
        item.dropDown &&
        typeof item.dropDown === 'object' &&
        item.dropDown.id
      ) {
        return item.dropDown.id;
      }

      return null;
    })
    .filter(item => typeof item === 'string') as string[];

  const dropdownWithDepth = useGetHeaderDropdownsQuery({
    ids: dropdownIds,
    depth: 1,
    locale: currentLocale || DEFAULT_LOCALE,
  });

  const actionButtons = addJurisdictionSupport({
    actionList: useActions(menuActions),
    localeList: locales,
  });

  const menuItemsPrepared = menuItems.map(item => {
    const preparedItem: Partial<
      | TMegaMenuHeaderItem
      | TMegaMenuHeaderItemSubMenuV1
      | TMegaMenuHeaderItemSubMenuV2
    > = {
      title: item.title,
    };

    if (item.id) {
      preparedItem.id = item.id;
    }

    if (item.link && Array.isArray(item.link)) {
      preparedItem.titleLink = item.link.map(titleLink =>
        toLinkProps(currentLocale, titleLink),
      );
    }

    if (item.dropDown) {
      const dropdown = dropdownWithDepth.data?.docs.find(
        doc =>
          typeof item.dropDown !== 'string' && doc.id === item.dropDown?.id,
      );

      if (!dropdown) {
        preparedItem.dropDown = [{ rowTitle: '', isLoading: true }];
        return preparedItem;
      }

      // TODO: TO avoid breaking changes, can be refactored after release
      const rows = Array.isArray(dropdown?.dropDownRow)
        ? dropdown?.dropDownRow
        : dropdown?.dropDownRow?.[currentLocale];

      const blockType = dropdown?.dropDownBlock?.[0]?.blockType;

      if (blockType === ESubMenuVersions.SubMenuV1) {
        preparedItem.dropDown = prepareSubMenuV1({ dropdown });
        preparedItem.type = ESubMenuVersions.SubMenuV1;
      } else if (blockType === ESubMenuVersions.SubMenuV2) {
        preparedItem.dropDown = prepareSubMenuV2({ dropdown });
        preparedItem.type = ESubMenuVersions.SubMenuV2;
      } else {
        // Default
        preparedItem.dropDown = prepareSubMenuV3({ rows });
        preparedItem.type = ESubMenuVersions.SubMenuV3;
      }
    }

    preparedItem.dataGaId = item?.dataGaId;

    return preparedItem;
  });

  const [mobileActionButton] = addJurisdictionSupport({
    actionList: useActions(ctaSettings?.mobileActions),
    localeList: locales,
  });

  return (
    <HeaderMegaMenu
      logoText={logoText}
      languageText={languageText}
      navMenuLogoTitle={navMenuLogoTitle}
      customHomeLink={customHomeLink}
      logoIcon={navMenuLogo || logoIcon}
      menuItems={
        menuItemsPrepared as
          | TMegaMenuHeaderItem[]
          | TMegaMenuHeaderItemSubMenuV2[]
      }
      actions={actionButtons}
      locales={locales}
      withNavMenu={withNavMenu}
      rightMenuActionsPosition={
        rightMenuActionsPosition as ERightMenuActionsPosition
      }
      mobileActionButton={mobileActionButton}
    />
  );
};

export default HeaderMegaMenuConnected;
