import { getGaId } from '@front/shared';
import clsx from 'clsx';
import React from 'react';

import { EDropDownItemRadius } from './DropdowItem.constants';
import { ReactComponent as IconCheckInDropDown } from '../../../../../public/icons/icon-check-in-dropdown.svg';

export type TDropdownItemProps = {
  title: string;
  isChecked?: boolean;
  onClick?: () => void;
  rounded?: EDropDownItemRadius;
};

export const DropdownItem: React.FC<TDropdownItemProps> = ({
  title,
  isChecked,
  onClick,
  rounded = EDropDownItemRadius.Lg,
}) => {
  return (
    <div
      className={clsx(
        'hover:bg-header-button-hover flex cursor-pointer items-center justify-between px-4 py-3',
        {
          'bg-header-button-hover': isChecked,
          'rounded-lg': rounded === EDropDownItemRadius.Lg,
          'rounded-2xl': rounded === EDropDownItemRadius.Xxl,
        },
      )}
      data-ga-id={getGaId(title, 'language_switcher')}
      onClick={onClick}
    >
      <div className='text-base font-medium leading-relaxed '>{title}</div>
      {isChecked && (
        <IconCheckInDropDown className='aspect-square h-4 w-4 text-brand-500' />
      )}
    </div>
  );
};
