import { toImageProps, toLinkProps } from '@front/shared/ds';
import { TMegaMenuHeaderItem } from '@front/shared/ds/HeaderMegaMenu';
import { HeaderDropdown } from '@shared/master-types';

import { useLocale } from '../../../../hooks';

type TUsePrepareSubMenuV3 = ({
  rows,
}: {
  rows: HeaderDropdown['dropDownRow'];
}) => TMegaMenuHeaderItem['dropDown'] | undefined;

export const usePrepareSubMenuV3 = (): TUsePrepareSubMenuV3 => {
  const { locale: currentLocale } = useLocale();

  return ({ rows }: { rows: HeaderDropdown['dropDownRow'] }) =>
    rows?.map(row => {
      const rowBody = Array.isArray(row.rowBody)
        ? row.rowBody
        : row.rowBody?.[currentLocale];
      return {
        rowTitle: row.rowTitle,
        rowIcon: toImageProps(row.rowIcon),
        rowId: row?.id,
        rowRender: row?.rowRender,
        rowBody: rowBody?.map(body => {
          return {
            description: body?.description,
            id: body.id,
            icon: toImageProps(body.icon),
            showIconOnMobile: body.showIconOnMobile,
            iconHasLightVariant: body?.iconHasLightVariant,
            link: Array.isArray(body?.link)
              ? body.link.map(singleLink => ({
                  ...toLinkProps(currentLocale, singleLink),
                  'aria-disabled': !!singleLink?.disabled,
                  text: singleLink.text,
                  dataGaId: singleLink?.dataGaId,
                }))
              : null,
          };
        }),
      };
    });
};
